

.main-app {
    line-height: 30px;
    font-family: 'Cinzel';
    font-size: 18px;
    font-weight: bold;
}
.app-card{
    border-radius: 5px;
    border: 1px solid #d9ecd8 !important;
    -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    /* box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%); */
    border: none;
    height: 130px;
    width: 240px;
}

.app-card:hover{
    background: #011b69db;
    transition: width 1s;
    cursor: pointer;
}
.app-card:hover > .card-block > .row > .col-10>h4 {
    color: #fff !important;
}

.no-underline:hover{
    text-decoration: none !important;
}
.progressbar-migrations{
    margin-bottom: 10px;
    overflow: hidden;
    color: lightgrey;
}
.progressbar-migrations .active {
    color: #345496 !important;
}
.progressbar-migrations li {
    list-style-type: none;
    font-size: 15px;
    width: 11.8%;
    float: left;
    position: relative;
    font-weight: 400;
}
#progressbar {
    margin-bottom: 10px;
    overflow: hidden;
    color: lightgrey
}
#edu-progressbar {
    margin-bottom: 10px;
    overflow: hidden;
    color: lightgrey
}
#progressbar .cancelled{
    color: #fe3232;
}
#edu-progressbar .cancelled{
    color: #fe3232;
}
#progressbar li.cancelled:before,
#progressbar li.cancelled:after {
    background-color: #fe3232;
}
#edu-progressbar li.cancelled:before,
#edu-progressbar li.cancelled:after {
    background-color: #fe3232;
}
#progressbar li.cancelled:after{
    background-color: #fe3232;
}
#edu-progressbar li.cancelled:after{
    background-color: #fe3232;
}
#progressbar .active {
    color: #345496
}
#edu-progressbar .active {
    color: #345496
}
@media only screen and (min-width: 768px) {
#progressbar li {
    list-style-type: none;
    font-size: 15px;
    text-align: center;
    width: 12%;
    float: left;
    position: relative;
    font-weight: 400;
    z-index: 1;
}
#edu-progressbar li {
    list-style-type: none;
    font-size: 13.7px;
    text-align: center;
    width: 11%;
    float: left;
    position: relative;
    font-weight: 400;
    z-index: 1;
}
}
@media only screen and (max-width: 768px) {
    #progressbar li {
        list-style-type: none;
        font-size: 10px;
        text-align: center;
        width: 25%;
        float: left;
        position: relative;
        font-weight: 400;
        z-index: 1;
    }
}
#progressbar #step1:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f0ea';
    font-weight: 900;
}
#progressbar #step2mid:before {
    content: "";
    width: 10px;
    height: 10px;
    margin-top: 21px;
}

#progressbar #step2:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f2b6';
    font-weight: 900;
}

#progressbar #step3:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f658";
    font-weight: 900;
}
#progressbar #step4:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f0e0"
}
#progressbar #step5:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f01c"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 17px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #345496
}
.progress {
    height: 11px
}

.progress-bar {
    background-color: #0056af;
}

.fit-image {
    width: 100%;
    object-fit: cover
}
.steps {
    font-size: 25px;
    color: gray;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: right
}
span.tag{
    font-size: 10px;
    background: #5cc1de;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.green {
    font-size: 10px;
    background: green;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.whatsapp {
    font-size: 10px;
    background: #95D03A;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.skype{
    font-size: 10px;
    background: #55acee;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.send{
    font-size: 10px;
    background: #9901b5;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.rec{
    font-size: 10px;
    background: #b52501;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.inperson{
    font-size: 10px;
    background: #ec0086;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.zoom{
    font-size: 10px;
    background: #ecb900;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}
span.tag.case{
    font-size: 10px;
    background: #27948e;
    color: #fff;
    padding: 6px 10px;
    border: none;
    border-radius: 10px;
    float: right;
}

#edu-progressbar #step1:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f0ea';
    font-weight: 900;
}
#edu-progressbar #step2mid:before {
    content: "";
    width: 10px;
    height: 10px;
    margin-top: 21px;
}

#edu-progressbar #step2:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f2b6';
    font-weight: 900;
}

#edu-progressbar #step3:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f658";
    font-weight: 900;
}
#edu-progressbar #step4:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f0e0"
}
#edu-progressbar #step5:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f01c"
}

#edu-progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 17px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#edu-progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#edu-progressbar li.active:before,
#edu-progressbar li.active:after {
    background: #345496
}

.fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.triangle-right {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 100px solid red;
    border-bottom: 50px solid transparent;
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.login-header {
    /* animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-name: slideInLeft; */
    border: solid transparent;
    background: #00183ec4;
    /* background: #64ea994f; */
    padding: 15px 10px 3px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-card {
    height: 370px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    width: 400px !important;
    background-color: rgb(0 24 79 / 50%) !important;
}
.card .login-card-footer {
    background-color: transparent !important;
}
.input-group-prepend span {
    width: 50px;
    background-color: #4e73df;
    color: black;
    border: 0 !important;
    height: 100%;
    overflow: hidden;
}

.login-form-control {
    border: 2px;
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
    font-family: Emoji;
}

.input-group-text {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.form-control:focus {
    font-family: sans-serif;
    box-shadow: 0 0.125rem 0.25rem rgba(5, 36, 52, 0.35) !important;
    /* border-bottom-right-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
    border-top-left-radius: 2px !important; */


}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.login_btn {
    color: black;
    width: 100px;
}

.login_btn:hover {
    color: black;
    background-color: #3cde3cde;
}
.login{
    padding: 15px 10px 3px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links {
    color: white;
}

.links a {
    margin-left: 4px;
}
.h-color{
    color: #3cde3cde;
}
.bg-theme-blue{
    background-color: #4e73df;
}
.bg-theme-green{
    background-color: #3cde3cde;
}
.hide{
    display: none;
}
/* @media only screen and (max-width: 825px) and (min-width: 200px) {
.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(0, 40%);
  }
} */
.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(0, 40%);
  }



.padding{
    padding: 3px 5px 3px 5px;
}
.widget-content {
    padding: 1rem;
    flex-direction: row;
    align-items: center;
}
.bg-happy-darkgreen {
    background-image: linear-gradient(to left bottom, #0c0537, #272458, #43417c, #6160a1, #7f82c8) !important;
}
.widget-content .widget-content-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
}
.widget-content .widget-content-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
}
.text-white {
    color: #fff !important;
}
.widget-content .widget-content-left .widget-subheading {
    opacity: .5;
}
.widget-content .widget-content-left .widget-heading {
    font-size: .88rem;
    line-height: 1.5;
    opacity: .8;
    font-weight: bold;
}
.card {
  /* box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03); */
  border-width: 0;
  transition: all .2s;
}
.widget-content .widget-content-right {
    margin-left: auto;
    font-size: 28px;
}
.widget-content .widget-numbers {
    font-weight: bold;
    font-size: 1.8rem;
    display: block;
}
.card:hover > .widget-content-wrapper > .widget-content-right {
    transition: all .2s;
    font-size: 34px;
}
.card:hover > .widget-content-wrapper > .widget-content-left > .d-flex > .yellow-text {
    transition: all .2s;
    font-size: 0.88rem;
}
.bg-happy-green {
    background-image: linear-gradient(to top, #051937, #133760, #1d598d, #1f7dbb, #12a4eb) !important;
}
.bg-night-sky {
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}
.bg-premium-dark {
    background-image: linear-gradient(to right, #434343 0%, black 100%) !important;
}
.bg-royal {
    background-image: linear-gradient(to right, #141e30, #243b55) !important;
}
.bg-night-fade {
    background-image: linear-gradient(to top, #182867, #182867, #182867, #182867, #182867);
}
.bg-grow-early {
    background: radial-gradient(759px at 14% 22.3%, rgb(10, 64, 88) 0%, rgb(15, 164, 102) 90%);
}
.bg-heavy-rain {
    background-image: linear-gradient(to top, #98773a, #ac853b, #c1923b, #d6a03b, #ecae3b) !important;
}
.bg-night-sky {
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}
.yellow-text{
    color: #f7b924;
    font-size: 0.8rem;
    font-weight: bold;
}
.icon-container {
    position: relative;
    display: inline-block;
  }
  
  .badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red; /* Customize the background color */
    color: white; /* Customize the text color */
    border-radius: 50%; /* Make it circular */
    padding: 4px 8px; /* Adjust padding as needed */
    font-size: 12px; /* Adjust font size as needed */
  }
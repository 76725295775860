
.icon-margin {
    margin-right: 5px; /* Adjust the margin-right value as needed */
  }
  .paginate{
    color: #13131394;
    font-size: 14px;
  }
  .seen{
    background: #f2f6fc;
  }

.form-tabs .custom-nav-link.active{
    background: #d2e3fc;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    letter-spacing: -0.015em;
    font-family: 'poppins';
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* width: 100%; */
    cursor: pointer;
    box-shadow: inset 0 0 0 1px transparent;
    color: black;
  }
  .form-tabs .nav-link{
    font-family: 'poppins';
  }
  @media (min-width: 992px) {
    .h-lg-100 {
      /* Add any specific styles for large and larger screens here */
      height: 100vh;
    }
  }
  .inbox-area{
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .textarea {
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }  
  .inbox-icon{
    background-color: aqua;
  }

  .custom-textarea{
    border: 1px solid #ccc;
    transition: box-shadow 0.3s ease;
    outline: none; /* Remove default outline */
  }
  .custom-textarea:hover,
  textarea:focus  {
    border: 2px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .modal-header{
    background-color: aliceblue;
  }
  .read{
    background: #f2f6fc;
  }
  .unread{
    background: white;
    color: black;
    font-weight: 500;
  }
  .rounded-text-div {
    background-color: red;
    color: white;
    padding: 4px 8px; /* Adjust padding as needed */
    border-radius: 50px; /* Makes the div rounded */
    display: inline-block; /* Ensures the div only takes up as much space as the content */
    font-size: 12px; /* Adjust font size as needed */
    text-align: center; /* Center the text inside the div */
    margin-left: 4px;
}

/* Sidebar.css */

.sidebar {
    width: 50px; /* Width of the collapsed sidebar */
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgb(1, 27, 105);/* Example background color */
    overflow-x: hidden;
    transition: width 0.3s;
    z-index: 1000; /* Ensure sidebar is above other content */
  }
  
  .sidebar.expanded {
    width: 250px; /* Width of the expanded sidebar */
  }
  
  .sidebar .sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Other styling for your sidebar content */
  }
  
  /* Ensure that the sidebar is always collapsed on mobile screens */
  @media (max-width: 768px) {
    .sidebar {
      width: 70px; /* Collapsed width for mobile */
    }
  
    .sidebar.expanded {
      width: 50px; /* Prevent expansion on mobile */
    }
    .main-content.sidebar-collapsed {
      margin-left: 70px; /* This should match the collapsed width of the sidebar */
    }
    
  }
  
  /* For desktop screens, you can adjust the width of the sidebar when expanded */
  @media (min-width: 768px) {
    .sidebar.collapsed {
      width: 100px; /* Collapsed width for desktop */
      background-color: #011b69;
    }
    .main-content.sidebar-collapsed {
      margin-left: 100px; /* This should match the collapsed width of the sidebar */
    }
  }
  .toggle-btn { /* Position it relative to the sidebar's position */
    top: 10px; /* Adjust the distance from the top as needed */
    left: 100%; /* Place it just outside the sidebar */
    z-index: 10; /* Ensure it's above the sidebar content */
  
    
  }
  
  .toggle-btn:hover {
    background-color: #096918; /* Slightly darker shade on hover for feedback */
  }
  .nav-collapse:hover{
    height: 24px;
    width: 24px;
    background-color: blue;
  }
  .nav-collapse {
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    color: #f0f0f0;
    display: flex;
    height: 20px;
    justify-content: center;
    left: 94%;
    position: absolute;
    transition: .1s;
    width: 20px;
    z-index: 12;
}
.open-nav {
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  color: #f0f0f0;
  display: flex;
  height: 18px;
  justify-content: center;
  left: 86%;
  position: absolute;
  transition: .1s;
  width: 18px;
  z-index: 11;
}
.open-nav:hover{
  height: 22px;
  width: 22px;
  background-color: blueviolet;
}
.collapsed .sidebar-content .menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  color: inherit; /* or any color you want */
}
.expanded .sidebar-content .menu-item {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 18px;
  text-decoration: none;
  width: 100%;
  color: inherit; /* or any color you want */
}

.collapsed .sidebar-content .menu-item .icon {
  font-size: 14px; /* Icon size */
  /* Space between icon and text */
}

.collapsed .sidebar-content .menu-item span {
  display: block; /* Ensure text is below the icon */
}
.expanded .sidebar-content .menu-item span {
  display: block; /* Ensure text is below the icon */
  padding-left: 12px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expanded .sidebar-content .menu-item:hover{
  color: #4aff65;
  text-decoration: none;
  transform: scale(1.03); 
  background-color: #3157c8;
}
.expanded .sidebar-content .menu{
font-size: 13px !important;
margin-top: 0px !important;
}
.expanded .sidebar-content .menu-item.active {
  left: 0;
  transition: 350ms;
}
.collapsed .sidebar-content .menu-item:hover{
  color: #4aff65;
  text-decoration: none;
  transform: scale(1.03); 
  background-color: #3157c8;
}
.collapsed .sidebar-content .menu-item.active {
  left: 0;
  transition: 350ms;
}

  
  
  
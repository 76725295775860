
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.body {
    font-family: 'Open Sans', sans-serif;
}


html, 
body {
    height: 100%;
    background: #f4f5f8 !important;
}

body>#root>div {
  height: 100vh;
}

body {
  /* background: #f4f5f8 !important; */
  margin: 0;
  height: 100vh;
  color: #353535;
  min-height: 100vh;
  padding: 0;
}

#root {
  /* overflow: auto; */
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
  height: 100%;
}
.row {
  --bs-gutter-x: 0 !important;
}

.row>* {
  --bs-gutter-x: 0 !important;
}
.custom-font4 {
  font-family: Cinzel,serif;
}
.white {
  color: #fff!important;
}
.justify-content-right{
  justify-content: right !important;
}
.main-content {
  margin-left: 50px; /* or the width of your collapsed sidebar */
  height: 90%;
  flex-grow: 1;
  transition: margin-left 0.3s;
  background: #f4f5f8;
}
.bold {
  font-weight: bold !important;
}
.fira-font {
  font-family: 'Fira Sans', sans-serif;
}

.sora-font {
  font-family: 'Sora', sans-serif;
}
.sans-serif {
  font-family: sans-serif;
}
.name-text {
  font-weight: 600;
  color: #6f6f6f;
  font-size: 13.5px !important;
}
.text-red{
  color: #ff0000b0!important;
}
.captital{
  text-transform: capitalize;
}

.custom-font3 {
  font-family: 'Cormorant', serif;
}
.font-f-roboto {
  font-family: 'Roboto', sans-serif;
}
.font-serif {
  font-family: serif;
}

.font-none {
  font-family: none;
}
.font-f-monst-sans {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.font-monst{
  font-family: 'Montserrat';
  font-weight: 500;
}
.bg-white-smoke{
  background: #f5f5f5;
}
.acc-note {
  font-family: sans-serif;
  font-size: 14.5px!important;
}

/* Adjust the margin when the sidebar is expanded */
.main-content.sidebar-expanded {
  margin-left: 250px; /* or the width of your expanded sidebar */
}

@media (min-width: 768px) {
  .main-content {
    margin-left: 250px; /* or the width of your expanded sidebar */
  }
}
.no-underline:hover{
  text-decoration: none !important;
}
.no-underline{
  text-decoration: none !important;
}
.alice-blue{
  background-color: aliceblue;
}
.beige{
  background-color: beige;
}



@media only screen and (max-width: 574px) and (min-width:200px) {
  .size-12{
    font-size: 10px;
  }
  .size-11 {
    font-size: 9px;
  } 
  .size-16 {
    font-size: 16px;
  }
  .size-18 {
    font-size: 18px;
  }
  .size-14 {
    font-size: 12px;
  }
  .h-5vh{
    height: 3vh;
  }
  
}
@media only screen and (max-width: 640px) and (min-width: 576px) {
  .size-12{
    font-size: 10px;
  }
  .size-11 {
    font-size: 9px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-18 {
    font-size: 18px;
  }
  .size-14 {
    font-size: 13px;
  }
  .h-5vh{
    height: 4.5vh;
  }
  
}
@media only screen and (max-width: 790px) and (min-width:641px){
  .size-12{
    font-size: 10px;
  }
  .size-11 {
    font-size: 9px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-18 {
    font-size: 18px;
  }
  .size-14 {
    font-size: 14px;
  }
  .h-5vh{
    height: 4.5vh;
  }
  
}
@media only screen and (max-width: 1279px) and (min-width:800px){
  .size-12 {
    font-size: 12px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-11 {
    font-size: 11px;
  }
  .size-14 {
    font-size: 14px;
  }
  .size-18 {
    font-size: 18px;
  }
  .h-5vh{
    height: 1.5vh;
  }
  
}
@media only screen and (max-width: 1300px) and (min-width: 1280px){
  .size-12 {
    font-size: 12px;
  }
  .size-11 {
    font-size: 11px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-14 {
    font-size: 14px;
  }
  .size-18 {
    font-size: 18px;
  }
  .h-5vh{
    height: 5.5vh;
  }
  
}
@media only screen and (max-width: 1790px) and (min-width: 1301px){
  .size-12 {
    font-size: 12px;
  }
  .size-11 {
    font-size: 11px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-14 {
    font-size: 14px;
  }
  .size-18 {
    font-size: 18px;
  }
  .h-5vh{
    height: 4vh;
  }
  
}
@media screen and (max-width: 2090px) and (min-width: 1800px) {
  .size-12 {
    font-size: 12px;
  }
  .size-11 {
    font-size: 11px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-14 {
    font-size: 14px;
  }
  .size-18 {
    font-size: 18px;
  }
  .h-5vh{
    height: 4vh;
  }
}
@media screen and (max-width: 4150px) and (min-width: 3733px) {
  .size-12 {
    font-size: 12px;
  }
  .size-16 {
    font-size: 16px;
  }
  .size-11 {
    font-size: 11px;
  }
  .size-14 {
    font-size: 14px;
  }
  .size-18 {
    font-size: 18px;
  }
  .h-5vh{
    height: 6vh;
  }
}
.acc-form {
  font-family: auto;
  font-size: 16px!important;
  background-color: #eff0ff!important;
}
.divLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}

.fp-loader {
  border: 7px solid #f3f3f32b;
  border-top: 7px solid #3498db;
  border-radius: 55%;
  /* width: 50px;
  height: 50px; */
  animation: spin 1s linear infinite;
}
.back-text {
  font-family: Cinzel;
  font-weight: 600;
  padding: 5px;
}
.back-icon {
  color: inherit;
  text-decoration: none;
  border: none;
  background: transparent;
}
.back-icon:hover {
  text-decoration: underline !important;
  color: blue !important;
  cursor: pointer;
}
.j-content-right {
  justify-content: right;
}
@media only screen and (min-width: 768px){
  .w-lg-90 {
      width: 90%!important;
  }
}
.btn-search{
  background-color: #EF7C00 !important;
  border-color: #e17807  !important;
  border-radius: 10px !important;
  color: white !important;
  padding: 8px 18px !important;
  gap: 12px !important;
}
.btn-search:hover{
  background-color: white !important;
  border-color: #e17807  !important;
  color: #EF7C00 !important;
}
.btn-search:focus {
  /* keyboard navigation */
  box-shadow: 0 0 0 0.22rem rgba(235, 140, 24, 0.35) !important;
}
.bg-grey {
  background: linear-gradient(to right, #6d6d6d, #c1c1c1);
}

.form-control:disabled {
  color: #696969;
}
.page-form {
  font-size: 15.5px !important;
  font-family: auto;
  height: calc(1.5em + 0.65rem + 2.8px) !important;
  padding: .0rem 2rem 0.0rem 0.6rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  color: #6e707e !important;
  vertical-align: middle !important;
  background-color: #fff !important;
  border: 1px solid #d1d3e2 !important;
  border-radius: 0.35rem !important;
}
.perpage {
  color: #000000ad;
}
.btn-info {
  color: white !important;
  background-color: #00a1c1 !important;
  border-color: #0099b7 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px !important;
}

.btn-info:hover {
  color: white !important;
  background-color: #2bc2e0 !important;
  border-color: #0099b7 !important;
}
.btn-inbox {
  color: white !important;
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px !important;
}

.btn-inbox:hover {
  color: white !important;
  background-color: #2bc2e0 !important;
  border-color: #0099b7 !important;
}
.table-scroll {
  overflow-x: scroll;
}
.table .thead-dark th {
  vertical-align: middle;
}
.table td {
  font-family: Sora;
  font-size: 14px;
  line-height: 1.7;
}
.table th {
  font-family: Sora;
  font-size: 13.5px;
  cursor: pointer;
}
.open-sans{
font-family: 'Open+Sans' !important;
}
.arrows thead th::after {
  color: #f7f7f747;
  content: '\f338';
  font-family: 'Font Awesome 5 Free';
  font-size: 11px;
  float: right;
  padding-top: 2px;
  z-index: 1;
}
thead th.asc::after {
    color: #eceff5;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    font-size: 12px;
    float: right;
    padding-top: 2px;
    z-index: 1;
}
thead th.desc::after {
  color: #eceff5;
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-size: 12px;
  float: right;
  padding-top: 2px;
  z-index: 1;
}
/* For smaller screens - keeps the table responsive */
.table-responsive {
  display: block !important;
  width: 100% !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
}
.row>* {
  --bs-gutter-x: 0!important;
}
.table-borderless tbody tr td, .table-borderless tbody tr th {
  border: 0;
}
.table .thead-dark th {
  vertical-align: middle;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
}
.table-bordered {
  border: 1px solid #dee2e6 !important;
}
@media only screen and (min-device-width: 1080px) and (max-device-width: 1280px){
  .pagination-float-right {
      float: right;
  }
}
@media only screen and (min-device-width : 1290px) and (max-device-width : 1600px) {
  .pagination-float-right {
    float: right;
  }
}
.acc-label {
  font-family: 'Poppins', sans-serif;
  /* margin-top: -1.5px; */
}
.label {
  font-family: 'Poppins', sans-serif;
  margin-top: 1.3px;
  font-size: 15px;
}
.size-15{
  font-size: 15px;
}
.light-grey {
  color: #353535b8;
}
.font-f-sans{
  font-family: sans-serif;
}
.acc-label2 {
  font-family: serif;
  color: #544d43;
}
@media only screen and (max-width: 600px) {
  /* .d-flex {
    flex-direction: column; 
  } */

  .label,
  .acc-label {
    width: 100%; /* Make elements take up the full width on small screens */
  }
  th, td {
    font-size: 12px;
  }
}
.font-poppin{
  font-family: poppins;
}

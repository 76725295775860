/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 3px 10px;
    background-color: white;
    height: 10% ;
  }
  
  .header .logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
  }
  
  .header .navigation ul {
    list-style: none;
    display: flex;
  }
  
  .header .navigation li {
    margin-left: 20px;
  }
  
  .header .navigation li a {
    color: white;
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .collapsed + .header{
      margin-left: 70px;  /* Collapsed width for mobile */
    }
  }
  @media (min-width: 768px) {
    .collapsed + .header{
      margin-left: 100px;  /* Collapsed width for desktop */
    }
  }
  .expanded + .header{
    margin-left: 250px;
  }

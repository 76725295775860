
.submitted{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    gap: 12px;
    background: #E2FBD7;
    border-left: 5px solid #34B53A;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    color: #34B53A;
}
.card-b-section{
    bottom: 0;
    position: absolute;
    background-color: unset !important;
    border-top: unset !important;
}

  /* form page css */
  /* .form-bg{
    background: #FEF2E6;
} */
.custom-nav-link{
    cursor: pointer;
}
.form-tabs .custom-nav-link.active {
    background: #2A99FB;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* width: 100%; */
    cursor: pointer;
}
.form-tabs .nav-link{
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #707070;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
}
.pointer{
    cursor: pointer;
}
#myTabs{
    align-items: flex-start;
}
.area{
    background: #FFFFFF;
    border: 1px solid rgba(112, 112, 112, 0.4);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.almost-done{
    flex: none;
    order: 0;
    flex-grow: 0;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    letter-spacing: -0.015em;
    color: #3C3C3C;
}
#progress{
    display: flex;
    justify-content: space-around;
  }
  .progress-item {
    display: flex;
    width: 85px;
    height: 83px;
    border-radius: 50%;
    font-size: 0;
    animation: .4s ease-out reverse; 
    color: #003D7C;
  }
  
  .progress-item::after {
    content: attr(data-value);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    margin: 6px;
    border-radius: 50%;
    background: white;
    font-size: 1rem;
    text-align: center;
  }
  .border-none{
    border: none!important;
  }
  .border-none{
    border: none!important;
  }
  .list-input{
    padding: 12px 24px;
    gap: 12px;
    background: rgba(0, 61, 124, 0.1);
    border-radius: 10px;
  }
  .controls > .form-check-input:checked {
    background-color: #707070;
    border-color: #707070;
  }
  .controls > .form-check-input {
    border: 1.5px solid #707070 !important;
  }
  .list-input:focus {
    background:  rgba(0, 61, 124, 0.1)!important;
    border: 1px solid #003D7C !important;
    box-shadow: 0px 0px 0px 3px rgba(0, 61, 124, 0.3) !important;
  }
  .input-icons i {
    position: absolute;
}
.input-icons {
    width: 100%;
    margin-bottom: 10px;
}
  
.icon {
    padding: 12px 0 12px 16px;
    color: #707070;
    min-width: 50px;
    text-align: center;
    position: absolute !important;
    margin-top: unset !important;
}
.info-field {
    width: 100%;
    padding: 10px;
    background:  rgba(0, 61, 124, 0.1);
    border: 1px solid #ced4da;
    box-shadow: none !important;
    line-height: 1.5;
    color: #212529;
}
.info-field:focus {
    width: 100%;
    padding: 10px;
    background:  rgba(0, 61, 124, 0.1);
    border: 1px solid #2A99FB !important;
    box-shadow: 0px 0px 0px 3px rgba(42, 153, 251, 0.3) !important;
    line-height: 1.5;
    color: #212529;
}
.icon ~ p{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: #707070;
    flex: none;
    order: 1;
    flex-grow: 1;
}
.underline{
    text-decoration: underline;
}
.no-underline{
    text-decoration: none !important;
}
.dropdown .dropdown-toggle::after {
    margin-left: 75%;
    margin-top: 2%;
}
.blue-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 12px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 61, 124, 0.4);
    border-radius: 10px;
    flex: none;
    order: 2;
    flex-grow: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #003D7C;
}
.remove-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 12px;
    background: #FFFFFF;
    border: 1px solid #dc3545;
    border-radius: 10px;
    flex: none;
    order: 2;
    flex-grow: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #dc3545;
}
.remove-btn:hover{
    background-color: #dc3545;
    color: white;  
}
.blue-btn:hover{
    background-color: #003D7C;
    color: white;
}
.warning{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FEEAED;
    border-left: 5px solid rgba(255, 58, 41, 0.4);
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    color: #F64669;
}
.card-inner{
    position: relative;
    height: 430px;
    overflow: hidden;
}
.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile:focus + label {
    /* keyboard navigation */
    outline: 1px dotted  #2A99FB;
  }
  .inputfile + label * {
    pointer-events: none;
  }
  @media only screen and (max-width: 574px) and (min-width:200px) {
    .apply{
        font-size: 8px;  
    }
    .controls {
        font-size: 9px;
    }
    .d-subheading {
        font-size: 11px;
    }
  }
  @media only screen and (max-width: 640px) and (min-width: 576px) {
    .apply{
        font-size: 8px;  
    }
    .almost-done{
        font-size: 18px;
        line-height: 22px;
    }
    .controls {
        font-size: 6px;
    }
    .d-subheading {
        font-size: 6px;
    }
  }
  @media only screen and (max-width: 790px) and (min-width:641px){
    .apply{
        font-size: 8px;  
    }
    .almost-done{
        font-size: 24px;
        line-height: 22px;
    }
    .controls {
        font-size: 8px;
    }
    .d-subheading {
        font-size: 8px;
    }
  }
  @media only screen and (max-width: 1279px) and (min-width:800px){
    .apply{
        font-size: 9px;  
    }
    .almost-done{
        font-size: 18px;
        line-height: 26px;
    }
    .controls {
        font-size: 9px;
    }
    .d-subheading {
        font-size: 9px;
    }
  }
  @media only screen and (max-width: 1300px) and (min-width: 1280px){
    .apply{
        font-size: 13px;
    }
    .almost-done {
        font-size: 18px;
        line-height: 26px;
    }
    .res-size-14{
        font-size: 14px;
    }
    .d-subheading {
        font-size: 13px;
    }
  }
  @media only screen and (max-width: 1790px) and (min-width: 1301px){
    .apply{
        font-size: 14px;
    }
    .almost-done{
        font-size: 24px;
        line-height: 36px;
    }
    .res-size-14{
        font-size: 14px;
    }
  }
  @media screen and (max-width: 2090px) and (min-width: 1800px) {
    .apply{
        font-size: 14px;
    }
    .almost-done{
        font-size: 24px;
        line-height: 36px;
    }
    .res-size-14{
        font-size: 14px;
    }
  }
  @media screen and (max-width: 4150px) and (min-width: 3733px) {
    .apply{
        font-size: 14px;
    }
    .almost-done{
        font-size: 24px;
        line-height: 36px;
    }
    .res-size-14{
        font-size: 14px;
    }
  }
  .apply{
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    background: #003D7C !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
    border: 1px solid #003D7C !important;
    color: white;
    line-height: 21px;
}
.controls{
    color: #707070;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.015em;
}
.text-left{
    text-align: left!important;
}
.d-subheading{
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.015em;
}
.list-p ~ div {
    flex: none;
    padding: 5px;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #707070;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.a-tag{
    color: #003D7C;
    text-decoration: none;
}
.i-text{
    color: #3C3C3C;
    font-weight: 600;
}
.almost-done{
    font-size: 20px;
}